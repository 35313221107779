import React from 'react';
import './Header.scss'

class Header extends React.Component{
  constructor(props){
    super(props)
    this.state = {color: 'black', position: 'fixed'}
  }

  listenScrollEvent = e => {
    if (window.scrollY < this.state.height) {
      this.setState({position: 'static'})
    } else {
      this.setState({position: 'fixed'})
    }
  }

  reportWindowSize = e => {
    let hero_height = document.getElementById('hero').clientHeight;
    this.setState({height: hero_height}, function(){
      this.listenScrollEvent();
    });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
    window.addEventListener('resize', this.reportWindowSize);
    this.reportWindowSize();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenScrollEvent, false);
    window.removeEventListener('resize', this.reportWindowSize, false);
  }


  render(){
    return(
      <div className="header__wrapper">
      {/*<div className={`smiley ${this.props.color}`}><h4><a href="/">ツ</a></h4></div>*/}
        <div className={`header ${this.state.position} ${this.props.color}`}>
          <h4 onClick={() => this.props.updateMenuState(true)}>Menu<span>ツ</span></h4>
        </div>
      </div>
    )
  }
}

export default Header

