import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";

import StartPage from '../containers/StartPage/StartPage';
import RecruitmentPage from '../containers/RecruitmentPage/RecruitmentPage';
import MainMenu from '../components/MainMenu/MainMenu';


class App extends React.Component{

  constructor(props){
    super(props)
    this.state = {menuOpen: false}
  }

  updateMenuState = (val) =>{
    this.setState({menuOpen: val})

    if(val === true){
      document.body.classList.add('overflow-hidden');
    }else{
      document.body.classList.remove('overflow-hidden');
    }

  }

  render(){
    return (
      <div className="App">
        {this.state.menuOpen ? <MainMenu updateMenuState={this.updateMenuState} /> : '' }
        <StartPage updateMenuState={this.updateMenuState} />;

      </div>

          //<Route exact path="/" render={({ menuOpen }) => {
            //return <StartPage updateMenuState={this.updateMenuState} />;
          //}} />
          //<Route exact path="/join" render={({ menuOpen }) => {
          //  return <RecruitmentPage updateMenuState={this.updateMenuState} />;
          //}} />

    );
  }
}

export default App;
