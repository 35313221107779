import React from 'react';

import {ReactComponent as LogoP} from './p-01.svg';
import {ReactComponent as LogoL} from './l-01.svg';
import {ReactComponent as LogoE} from './e-01.svg';
import {ReactComponent as LogoI} from './i-01.svg';
import {ReactComponent as PleiVentures} from './plei-ventures-logo.svg';


import './StartPageHero.scss';

class StartPageHero extends React.Component{
  constructor(props){
    super(props)
    this.state = {}
    this.container = React.createRef();
    this.element = React.createRef();

    this.letterP = React.createRef();
    this.letterL = React.createRef();
    this.letterE = React.createRef();
    this.letterI = React.createRef();
  }

  listenScrollEvent = e => {
    let containerHeight = this.container.current.offsetHeight;
    let scrollTop = document.documentElement.scrollTop;

    let opacity = 1
    opacity = 1 - scrollTop / containerHeight

    if (opacity >= 0) {
      this.container.current.style.opacity = opacity;
    }else{
      return
    }

    let offsetP = containerHeight/5
    let offsetL = 1;
    let offsetE = containerHeight/10
    let offsetI = 1;

    this.letterP.current.style.transform = this.getTransform(offsetP, 0.8, 10, true, scrollTop);
    this.letterL.current.style.transform = this.getTransform(offsetL, 1, 1, false, scrollTop);
    this.letterE.current.style.transform = this.getTransform(offsetE, 0.6, 2, true, scrollTop);
    this.letterI.current.style.transform = this.getTransform(offsetI, 1.4, 0, true, scrollTop);

  }

  getTransform = (offset, tFactor, rFactor, rNeg, scrollTop) => {

    let translate = (scrollTop * tFactor) - offset
    translate = translate > 0 ? translate : 0;

    let rotate = Math.log( (scrollTop) * rFactor ) - Math.log(offset)
    rotate = rotate > 0 ? rotate : 0;
    
    if (rNeg){
      return 'translateY(' + (-translate) + 'px) rotate(' + -rotate + 'deg)';
    }else{
      return 'translateY(' + (-translate) + 'px) rotate(' + rotate + 'deg)';
    }

  }

  componentDidMount(){
    window.addEventListener('scroll', this.listenScrollEvent);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenScrollEvent, false);
    window.removeEventListener('resize', this.handleResize, false);
  }
 

  render(){
    return(
      <div className="start-herospace" id="hero">
        <div className="start-herospace--wrapper" ref={this.container}>
            <span className="letter p" ref={this.letterP}><LogoP /></span>
            <span className="letter l" ref={this.letterL}><LogoL /></span>
            <span className="letter e" ref={this.letterE}><LogoE /></span>
            <span className="letter i" ref={this.letterI}><LogoI /></span>
        </div>
      </div>
    )
  }
}

export default StartPageHero

