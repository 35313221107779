import React from 'react';
import './MainMenu.scss'

class MainMenu extends React.Component{
  constructor(props){
    super(props)
    this.state = {}
  }

  render(){
    return(
        <div className="menu__container">
          <div className="menu__items">
            <h1 className="item__menu">Menu</h1>
            <h1 className="item__link">
              <a href="https://www.instagram.com/weareplei/">Instagram</a>
            </h1>
            <h1 className="item__link">
              <a href="https://www.linkedin.com/company/pleiventure/">LinkedIn</a>
            </h1>
            <h1 className="item__link">
              <a href="mailto:philip@plei.io">Contact</a>
            </h1>
            <h1 className="item__close"onClick={() => this.props.updateMenuState(false)}>
              <span>Close</span>
            </h1>
          </div>
        </div>
    )
  }
}

export default MainMenu

