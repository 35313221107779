import React from 'react';

import './Investments.scss';

class Investments extends React.Component{
  constructor(props){
    super(props)
    this.state = {elements: []}
    this.section = React.createRef();
  }



  listenScrollEvent = e => {
    let scrollTop = document.documentElement.scrollTop
    let scrollBottom = scrollTop + window.innerHeight;
    let scrollPastSection = this.state.section.getBoundingClientRect().top - window.innerHeight

      this.state.elements.forEach( el => {
        this.fadeInElement(el, scrollBottom, scrollPastSection)
        return
      });
  }

  fadeInElement = (el, scrollBottom, scrollPastSection) => {
    let elementPosition = el.getBoundingClientRect();
    let passed = (elementPosition.y - window.innerHeight) + (window.innerHeight * 0.1)
    
    if (passed < 0){
      el.style.opacity = 1;
    }

    if (scrollPastSection > 0){
      el.style.opacity = 0;
    }


  }

  componentDidMount() {
    const section = this.section.current;
    const elements = section.querySelectorAll('h2');
    this.setState({elements: elements, section: section}, () => {
      window.addEventListener('scroll', this.listenScrollEvent)
    });


  }

  componentWillUnmount() {

  }


  render(){

    return(
      <section className="section__investments" ref={this.section}>
        {this.props.investments.map(d => (
          <div className="investment" key={d.id}>
            <h2>
              <a href={d.url}>{d.name}</a>
              {d.note === 'mna' ? <span>M&A</span> : ""}
              {d.note === 'ipo' ? <span>IPO</span> : ""}
            </h2>
          </div>
        ))}
      </section>
    )
  }
}

export default Investments

