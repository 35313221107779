import React from 'react';

import './Tasks.scss';


interface Props {
}

class EntrepreneurTasks extends React.Component <Props>{
  constructor(props){
    super(props)
    this.state = {}
  }

  render(){
    return(
    <section className="section__tasks">
      <div className="task flex flex-desktop">
        <h3 className="col-1">Typical tasks</h3>
        <div className="task__content col-2">
          <ul>
          <li>
            <span>→</span>
            <p>Set up a sourcing and distribution network for a product in three weeks</p>
          </li>
          <li>
            <span>→</span>
            <p>Build a huge influencer program for a brand from scratch</p>
          </li>
          <li>
            <span>→</span>
            <p>Find all the progressive wine bars in Europe and get them hooked</p>
          </li>
          <li>
            <span>→</span>
            <p>Source the most sustainable PU-material currently on the market in a low MOQ</p>
          </li>
          <li>
            <span>→</span>
            <p>Host non cringe entrepreneur-events that people love</p>
          </li>
          <li>
            <span>→</span>
            <p>Sell, sell sell. Mingle, mingle, mingle.</p>
          </li>
          <li>
            <span>→</span>
            <p>Find new talent for our different startups and get them onboard</p>
          </li>
          <li>
            <span>→</span>
            <p>Build business cases and test ideas</p>
          </li>
          <li>
            <span>→</span>
            <p>Everything else.</p>
          </li>
          </ul>
        </div>
      </div>
      <div className="task flex flex-desktop">
        <h3 className="col-1">Dealbreakers</h3>
        <div className="task__content col-2">
          <li>
            <span>→</span>
            <p>You’re not willing to work your ass off, sorry but this is not a work-life balance gig</p>
          </li>
          <li>
            <span>→</span>
            <p>You’re in it for the short term gain, we offer stock and a long term money making machine</p>
          </li>
          <li>
            <span>→</span>
            <p>You’re too senior for certain tasks</p>
          </li>
          <li>
            <span>→</span>
            <p>You’re a douche or too nice, please be a combination!</p>
          </li>
          <li>
            <span>→</span>
            <p>You hate the earth and/or good design</p>
          </li>
      </div>
      </div>
      <div className="task flex flex-desktop">
        <h3 className="col-1">About Plei</h3>
        <div className="task__content col-2">
          <p>Plei is a new type of startup power house and investor, based in Stockholm. We engage in disruptive, smart and good-for-the-world ideas. Together with talented co-founders we also develop our own start ups from scratch and launch them to the world.</p>
        </div>
      </div>

    </section>

    )
  }


}

export default EntrepreneurTasks

