import React from 'react';
import ReactDOM from 'react-dom';
//import {BrowserRouter as Router, Route} from "react-router-dom";
import App from './App/App';

import './styles/_breakpoints.scss';
import './styles/_typography.scss';
import './styles/layout.scss';

import './index.scss';

const rootElement = document.getElementById('root')

const element = (
  <App />
)

ReactDOM.render(element, rootElement);
