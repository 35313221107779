import React from 'react';

import {ReactComponent as Arrow} from './arrow-bend.svg';

import './Headline.scss';

class Headline extends React.Component{
  constructor(props){
    super(props)
    this.state = {}
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render(){
    return(
      <section className={`section__headline ${this.props.customClass}`}>
        <div className="center-text">
          <h2>
            {this.props.text}
          </h2>
          {this.props.arrow ? <div className="down-arrow"><Arrow /></div> : '' }
        </div>
      </section>
    )
  }
}

export default Headline;

