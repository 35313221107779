import React from 'react';

import FadeInSection from '../../helpers/FadeInSection';

import './ImageGridItem.scss'

interface Props {
  headline: String,
  body: String,
  link: String,
  image: String,
}

class ImageGridItem extends React.Component <Props>{
  constructor(props){
    super(props)
    this.state = {}
  }

  render(){
    return(

      <div className="image-grid-item__wrapper col-1">
      <FadeInSection>


      {this.props.link ?
        <a href={this.props.link} target="_blank" rel="noreferrer">

          <div className="image__wrapper">
            <div className="overlay"><h2>↝</h2>{/*<h4>{this.props.headline}</h4>*/}</div>
            <div className="img__container">
              <img src={this.props.image} alt=""/>
            </div>
          </div>
          <h4>{this.props.headline}</h4>
          <p>{this.props.body}</p>
        </a>
        :
        <div>

        <div className="image__wrapper secret">
          <img src={this.props.image} alt=""/>
        </div>
        <h4>{this.props.headline}</h4>
        <p>{this.props.body}</p>
        </div>

      }

      </FadeInSection>
      </div>


    )
  }


}

export default ImageGridItem

