import React from 'react';

import './LargeCTA.scss';

class LargeCTA extends React.Component{
  constructor(props){
    super(props)
    this.state = {}
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }


  render(){
    return(
      <section className="cta-large__wrapper flex">
        <a href="mailto:philip@plei.io" className="col-1 btn btn-green btn-large">Apply Now</a>
      </section>

    )
  }
}

export default LargeCTA;

