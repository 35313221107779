import React from 'react';

import './Hero.scss';

//import {ReactComponent as Arrow} from '../LargeText/arrow-bend.svg';

class Hero extends React.Component{
  constructor(props){
    super(props)
    this.state = {}
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }


  render(){
    return(
      <div className="herospace" id="hero">

        <div className="herospace__content" >
          <h1>{this.props.headline}</h1>
        </div>
      {/*
        <div className="arrow"><Arrow /></div>
      */}

        {this.props.bgVideo ?
          <video autoplay="autoplay" loop="loop" muted="muted" playsinline="playsinline">
            <source src={this.props.bgVideo} type="video/mp4"/>
            <source src={this.props.bgVideoWebm} type="video/webm"/>
          </video>
          : "" }
      </div>
    )
  }
}

export default Hero

