import React from 'react';

import {ReactComponent as Arrow} from './arrow-bend.svg';

import './SubHeadline.scss';

class SubHeadline extends React.Component{
  constructor(props){
    super(props)
    this.state = {}
    this.container = React.createRef();
    this.element = React.createRef();
  }

  listenScrollEvent = e => {


    let containerHeight = this.container.current.offsetHeight;
    let scrollTop = document.documentElement.scrollTop

    let opacity = 1

    if (scrollTop >= this.container.current.offsetTop && opacity >= 0){
      opacity = 1 - (scrollTop - this.container.current.offsetTop) / containerHeight
      this.element.current.style.opacity = opacity;
    }

  }





  componentDidMount(){
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenScrollEvent, false);
  }
 

  render(){
    return(
    <section className={`flex section__sub-headline ${this.props.theme}`} 
      ref={this.container}>
      <div className="col-2" ref={this.element}>
        <h3>{this.props.text}</h3>
        {this.props.arrow ? <div className="down-arrow"><Arrow /></div> : '' }
      </div>
    </section>

    )
  }
}

export default SubHeadline;

